import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { Guid } from "guid-typescript";
import _, { debounce } from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KDebounceSelect } from "./k-debounceSelect";
import "./selectbox.scss";

declare let window: any;

export interface IKSelectBoxProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  widthType?: "fill" | "content";
  searchable?: boolean;
  getTextFromValue?: (value: any) => void;
  onChange: (value: any) => void;
  defaultText: string;
  sortBy?: string;
  containsNullItem?: boolean;
  autoRefresh?: boolean;
}

interface IKSelectBoxState {
  uniqueKey: Guid;
}

class KSelectBox extends PureComponent<IKSelectBoxProps & CommonProps, IKSelectBoxState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IKSelectBoxProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  public getAllData = () => {
    return this.props.options;
  };

  componentDidMount = () => {
    this.setDynamicStyle();
    this.handleComponentStyling();
    if (
      this.props.autoRefresh &&
      this.props.value === undefined &&
      this.props.onChange &&
      this.props.mode === "multiple" &&
      typeof this.props.onChange === "function"
    ) {
      this.props.onChange([]);
    }
  };

  componentDidUpdate = () => {
    this.handleComponentStyling();
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getBgColor = (): string | undefined => {
    if (this.props.style?.backgroundColor) {
      return `background-color: ${this.props.style?.backgroundColor} !important;`;
    }
  };

  getFontSize = (): string | undefined => {
    if (this.props.style?.fontSize) {
      return `font-size: ${this.props.style?.fontSize.toString()} !important;`;
    }
  };

  getFontWeight = (): string | undefined => {
    if (this.props.style?.fontWeight) {
      return `font-weight: ${this.props.style?.fontWeight} !important;`;
    }
  };

  getColor = (): string | undefined => {
    if (this.props.style?.color) {
      return `color: ${this.props.style?.color};`;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    const bgColor = this.getBgColor();
    const color = this.getColor();
    const fontSize = this.getFontSize();
    const fontWeight = this.getFontWeight();
    let result = "";

    if (this.props.style?.color) {
      if (this.props.style?.color.substring(0, 4) === "rgba") {
        const match = /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*\d+[\.\d+]*)*\)/g.exec(this.props.style?.color);
        if (match) {
          const newColor = `rgba(${[match[1], match[2], match[3], 0.15].join(",")})`;
          result += `
            .kdropdown-${className.trim()} .ant-select-item-option:hover,
            .kdropdown-${className.trim()} .ant-select-item-option-active {
              background-color: ${newColor} !important;
            }`;
        }
      }
    }

    if (bgColor || color || fontSize || fontWeight) {
      result += `.${className.trim()} > .ant-select-selector {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;

      result += `.${className.trim()} > .ant-select-arrow {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;

      result +=
        `
      .` +
        `kdropdown-${className.trim()} {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;

      result +=
        `
        .` +
        `kdropdown-${className.trim()} .ant-select-item-option {
            ${color !== undefined ? color : ``}
          }`;

      result +=
        `
        .` +
        `kdropdown-${className.trim()} .ant-select-item-option-content {
            ${fontSize !== undefined ? fontSize : ``}
            ${color !== undefined ? color : ``}
          }`;
      result +=
        `
          .` +
        `kdropdown-${className.trim()} .ant-select-item-option-content {
              ${fontWeight !== undefined ? fontWeight : ``}
            }`;
    }

    if (this.props.style?.color || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-select-selection-search-input, .ant-select-selection-item {
            `;
      if (this.props.style?.color) result += `color: ${this.props.style?.color} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.fontSize || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-select-selection-placeholder {
            `;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    const paddingBottom: any = this.props.style?.paddingBottom;
    const paddingLeft: any = this.props.style?.paddingLeft;
    const paddingRight: any = this.props.style?.paddingRight;
    const paddingTop: any = this.props.style?.paddingTop;

    result += `.${className.trim()} .ant-select-selector {
          `;
    if (paddingBottom) result += `padding-bottom: ${paddingBottom}px !important;`;
    if (paddingLeft) result += `padding-left: ${paddingLeft}px !important;`;
    if (paddingRight) result += `padding-right: ${paddingRight}px !important;`;
    if (paddingTop) result += `padding-top: ${paddingTop}px !important;`;

    result += `;
      }`;

    result += `
          .${className.trim()} .ant-select-selection-placeholder {
            `;
    result += `color: #bfbfbf !important;`;
    result += `;
      }`;

    result += `
    .${className.trim()} .ant-select-selection-search-input {
      height: 100% !important;
    }`;

    result += `
    .${className.trim()} .ant-select-selection-search {
      margin-left: 0 !important;
      inset-inline-start: unset !important;
      inset-inline-end: unset !important;
    }`;

    result += `
    .${className.trim()} .ant-select-selection-placeholder {
      inset-inline-start: unset;
      inset-inline-end: unset;
      line-height: inherit !important;
    }`;

    result += `
    .${className.trim()} .ant-select-selector:after {
      line-height: inherit !important;
      margin: 0 !important;
    }`;

    result += `
    .${className.trim()} .ant-select-selection-item {
      line-height: inherit !important;
      margin: 0 !important;
    }`;
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kselectbox_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  handleComponentStyling = () => {
    if (!this.props.style) return;
    const { style } = this.props;
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    const input = node.querySelector(".ant-select-selection-search-input") as HTMLElement;
    const isMultiple = node?.classList?.contains("ant-select-multiple");
    if (input) {
      input.setAttribute("focusid", this.props.id);
    }
    if (!isMultiple) {
      if (this.props.style?.height) {
        this.handleHeight(item);
      } else {
        item.style.height = "40px";
      }

      if (this.props.style?.minHeight) {
        this.handleMinHeight(item);
      } else if (this.props.style?.height) {
        item.style.minHeight = this.props.style?.height as string;
      } else {
        item.style.minHeight = "40px";
      }

      if (this.props.style?.maxHeight) {
        this.handleMaxHeight(item);
      }
    }
    if (this.props.style?.color) {
      this.handleColor(node, style);
    }
    if (this.props.style?.fontSize) {
      this.handleFontSize(node);
    }
    if (this.props.style?.fontWeight) {
      this.handleFontWeight(item, style);
    }

    this.handleBorderRadius(item, style);
    this.handleBorder(item, style);
  };

  handleHeight = (item: HTMLElement) => {
    item.style.height = this.props.style!.height as any;
    item.style.alignItems = "center";
  };

  handleMinHeight = (item: HTMLElement) => {
    item.style.minHeight = this.props.style!.minHeight as any;
  };

  handleMaxHeight = (item: HTMLElement) => {
    item.style.maxHeight = this.props.style!.maxHeight as any;
  };

  handleFontWeight = (item: HTMLElement, style: React.CSSProperties) => {
    if (style?.fontWeight && item && item.style) {
      item.style.fontWeight = this.props.style!.fontWeight.toString();
    }
  };

  handleBorderRadius = (item: HTMLElement, style: React.CSSProperties) => {
    if (style?.borderRadius !== undefined) {
      item.style.borderRadius = `${style.borderRadius.toString()}px` as any;
    }
    if (style?.borderTopLeftRadius !== undefined) {
      item.style.borderTopLeftRadius = `${style.borderTopLeftRadius.toString()}px` as any;
    }
    if (style?.borderTopRightRadius !== undefined) {
      item.style.borderTopRightRadius = `${style.borderTopRightRadius.toString()}px` as any;
    }
    if (style?.borderBottomLeftRadius !== undefined) {
      item.style.borderBottomLeftRadius = `${style.borderBottomLeftRadius.toString()}px` as any;
    }
    if (style?.borderBottomRightRadius !== undefined) {
      item.style.borderBottomRightRadius = `${style.borderBottomRightRadius.toString()}px` as any;
    }
  };

  handleBorder = (item: HTMLElement, style: React.CSSProperties) => {
    if (style?.border) {
      item.style.border = style.border as any;
    }
    if (style?.borderRadius) {
      item.style.borderRadius = style.borderRadius as any;
    }
    if (style?.borderWidth) {
      item.style.borderWidth = style.borderWidth as any;
    }
    if (style?.borderColor) {
      item.style.borderColor = style.borderColor as any;
    }
    if (style?.borderStyle) {
      item.style.borderStyle = style.borderStyle as any;
    }
    if (style?.borderTopWidth != undefined) {
      item.style.borderTopWidth = `${style.borderTopWidth.toString()}px` as any;
    }
    if (style?.borderRightWidth != undefined) {
      item.style.borderRightWidth = `${style.borderRightWidth.toString()}px` as any;
    }
    if (style?.borderBottomWidth != undefined) {
      item.style.borderBottomWidth = `${style.borderBottomWidth.toString()}px` as any;
    }
    if (style?.borderLeftWidth != undefined) {
      item.style.borderLeftWidth = `${style.borderLeftWidth.toString()}px` as any;
    }
  };

  handleColor = (node: Element, style: React.CSSProperties) => {
    const selectionItem: HTMLElement = node.getElementsByClassName("ant-select-selection-item")[0] as HTMLElement;
    if (selectionItem) {
      selectionItem.style.color = style?.color as any;
    }
  };

  handleFontSize = (node: Element) => {
    const item = node.firstChild as HTMLElement;
    item.style.fontSize = this.props.style!.fontSize as any;
    item.style.alignItems = "center";
  };

  getProps = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    const props: any = {};
    props.datatextfield = this.props.datatextfield;
    props.datavaluefield = this.props.datavaluefield;
    props.widthType = this.props.widthType;
    props.searchable = this.props.searchable;
    props.options = this.props.options;
    props.onChange = this.props.onChange ?? _.noop;
    props.placeholder = this.props.placeholder;
    props.onBlur = isDesignTime ? () => {} : this.props.onBlur ?? _.noop;
    props.className = this.props.className;
    props.allowClear = this.props.allowClear;
    props.showSearch = this.props.showSearch;
    props.mode = this.props.mode;
    if (typeof this.props.maxTagCount == "number" && this.props.maxTagCount >= 0) {
      props.maxTagCount = this.props.maxTagCount;
    }

    let style: any | undefined = _.clone(this.props.style);
    if (props.options) {
      delete props.options;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    if (style) {
      style.border = "none";
      delete style.padding;
      delete style.paddingTop;
      delete style.paddingRight;
      delete style.paddingBottom;
      delete style.paddingLeft;
      delete style.borderLeftWidth;
      delete style.borderBottomWidth;
      delete style.borderRightWidth;
      delete style.borderTopWidth;
      delete style.borderTopLeftRadius;
      delete style.borderTopRightRadius;
      delete style.borderBottomLeftRadius;
      delete style.borderBottomRightRadius;
      delete style.backgroundColor;

      if (style.display == "block") {
        style.display = "flex";
      } else if (style.display == "inline") {
        style.display = "inline-flex";
      }
    }
    props.style = style;
    if (props.value == "") {
      delete props.value;
    }
    if (props.value) {
      delete props.value;
    }
    if (isDesignTime) {
      props.getPopupContainer = (triggerNode) => triggerNode.parentNode;
    }
    return props;
  };

  getOptions = (): object[] | undefined => {
    const isDesignTime = window.kuika?.isDesignTime;
    const dataTextField = this.props.datatextfield;
    const dataValueField = this.props.datavaluefield;
    if (dataTextField && dataValueField && dataTextField.length > 1 && dataValueField.length > 1) {
      let options: any[] = [];
      if (this.props.options && this.props.options.length && this.props.options.length > 0) {
        this.props.options.forEach((row) => {
          if (
            !_.isUndefined(row) &&
            !_.isUndefined(row[dataTextField!]) &&
            row[dataValueField] !== null &&
            row[dataValueField] !== undefined
          ) {
            options.push({
              label: row[dataTextField!],
              value: row[dataValueField],
              key: row[dataValueField]
            });
          }
        });
      }

      if (this.props.sortBy) {
        if (this.props.sortBy == "A-Z") {
          options = options.sort((a, b) => a?.label?.localeCompare(b.label));
        } else if (this.props.sortBy == "Z-A") {
          options = options.sort((a, b) => b?.label?.localeCompare(a.label));
        }
      }

      if (this.props.containsNullItem) {
        options.unshift({
          label: this.props.placeholder ?? "",
          value: null,
          key: "null"
        });
      }

      return options;
    }
    if (isDesignTime) {
      let options: any[] = [];
      if (this.props.options && this.props.options.length) {
        this.props.options.forEach((element) => {
          options.push({
            label: element.text ?? element.value,
            value: element.text ?? element.value,
            key: element.key ?? element.value
          });
        });
      }
      return options;
    }

    return this.props.options;
  };

  getFormValue = () => {
    return this.props.value;
  };

  getValue = () => {
    if (this.props.mode == "multiple") {
      if (this.props.value === "") {
        return [];
      }
      if (typeof this.props.value === "string") {
        return this.props.value.split(",");
      }
      return this.props.value;
    }
    if (this.props.searchable !== true) {
      let value;
      const options = this.getOptions() as any;
      if (options && options.length) {
        options.forEach((element) => {
          if (element.value == this.props.value || element.value == this.props.defaultValue) {
            // 2 eşittir olacak
            value = element.value;
          }
        });
      }
      return value;
    }

    if (window.kuika?.dashboardState === 1 && (this.props.value === "" || this.props.value === undefined)) {
      // Designer
      return undefined;
    }

    let { defaultText } = this.props;
    if (this.pressedClear === true) {
      defaultText = "";
    }
    let value;
    let text: undefined | string;
    const options = this.getOptions() as any;
    if (options && options.length) {
      options.forEach((element) => {
        if (element && element.value && element.value == this.props.value) {
          // 2 eşittir olacak
          value = element;
          text = element.label;
        }
      });
    }
    if (text !== undefined && this.props.value && this.refreshCounter === 2) {
      this.refreshCounter = 1;
      this.searchValue = "";
      this.handleChange({ key: this.props.value, label: text, value: this.props.value });
    }
    if (value === undefined && this.props.searchable === true) {
      if (
        this.props.value != undefined &&
        this.props.value.toString().length > 0 &&
        this.props.getTextFromValue &&
        (this.props.defaultText === undefined || this.props.defaultText === "") &&
        this.refreshCounter === 0
      ) {
        this.refreshCounter++;
        this.props.getTextFromValue(this.props.value);
      }
    }
    if (text === undefined || text === "") {
      if (
        defaultText &&
        defaultText.length > 0 &&
        this.refreshCounter === 1 &&
        (this.searchValue === undefined || this.searchValue === "")
      ) {
        this.refreshCounter++;
        this.waitAndSearch(defaultText);
      }
      text = defaultText;
    }
    if (this.props.value == undefined && text == undefined) {
      return undefined;
    }
    return { key: this.props.value, label: text, value: this.props.value };
  };

  refreshCounter: number = 0;

  searchValue = "";

  pressedClear: boolean = false;

  getSearchValue = () => {
    return this.searchValue;
  };

  waitAndSearch = debounce((value) => this.handleSearch(value), 50);

  handleSearch = (value) => {
    this.pressedClear = false;
    this.searchValue = value;
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  };

  handleGetTextFromValue = (value: any) => {
    if (this.props.getTextFromValue) {
      this.props.getTextFromValue(value);
    }
  };

  handleChange = (value: any) => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (value === undefined) {
      this.searchValue = "";
      this.pressedClear = true;
      if (this.props.onChange) {
        this.props.onChange(undefined);
      }
    }
    if (this.props.mode == "multiple") {
      if (isDesignTime) return;
      this.props.onChange(value);
    } else if (value !== undefined && value.key && this.props.onChange) {
      this.props.onChange(value.key);
    } else if (value !== undefined && this.props?.onChange) {
      if (isDesignTime) return;
      this.props.onChange(value);
    }
  };

  render(): ReactNode {
    return (
      <>
        {this.props.searchable === true ? (
          <KDebounceSelect
            value={this.getValue()}
            dropdownClassName={`kdropdown-${this.getClassName().trim()}`}
            {...this.getProps()}
            options={this.getOptions() as any}
            onSearchValueChange={this.handleSearch}
            getTextFromValue={this.handleGetTextFromValue}
            className={`k-selectbox-component${this.getClassName()}`}
            onChange={this.handleChange}
          />
        ) : (
          <Select
            filterOption={(input, option) => {
              // @ts-ignore
              return option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
            }}
            showSearch={this.props.showSearch}
            value={this.getValue()}
            {...this.getProps()}
            options={this.getOptions() as any}
            className={`k-selectbox-component${this.getClassName()}`}
            popupClassName={`kdropdown-${this.getClassName().trim()}`}
            onChange={this.handleChange}
          ></Select>
        )}
      </>
    );
  }
}

const kSelectBox = withCommonEvents(KSelectBox);
export { kSelectBox as KSelectBox };
